import React from "react"

export default function PrivacyHeroComponent(props) {
  return (
    <div
      className="bg-cover"
      style={{
        backgroundColor: props.backgroundColor,
        backgroundImage: `URL(${props.backgroundImage})`,
      }}
    >
      <div className="container max-w-screen-xl items-center pt-10 lg:pt-14">
        <div
          className={`bg-white w-full rounded-t-[15px] px-10 py-[1.875rem] text-center mt-[15px] ${props.shadowColor}`}
        >
          <h1 className="text-balance my-0">{props.title}</h1>
          <span>{props.body}</span>
        </div>
      </div>
    </div>
  )
}
