import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { colors } from "../utils/colors"
import PrivacyHeroComponent from "../components/herocomponents/privacyherocomponent"
import GRAY_BG from "../images/background-images/bg-gray.svg"

const PrivacyPolicy = () => (
  <Layout>
    <SEO
      title={`Privacy Policy | Surplus Solutions`}
      description={`Learn about how Surplus Solutions collects, uses, and protects your personal data. Our privacy policy ensures your information is handled with the utmost care.`}
    />
    <PrivacyHeroComponent
      title="Privacy Policy"
      body={
        <p className="pt-4">
          Surplus Solutions LLC is committed to protecting your privacy and
          developing technology that gives you the most powerful and safe online
          experience. This Statement of Privacy applies to the Surplus Solutions
          LLC Web site and governs data collection and usage. By using the
          Surplus Solutions LLC website, you consent to the data practices
          described in this statement.
        </p>
      }
      backgroundColor={colors.lightGray}
      backgroundImage={GRAY_BG}
      shadowColor="shadow-gray-top"
    />

    <div className="container w-full max-w-screen-xl py-14 shadow-custom-out">
      <h2>Collection of Your Personal Information</h2>
      <p>
        Surplus Solutions LLC collects personally identifiable information, such
        as your e-mail address, name, home or work address or telephone number.
        Surplus Solutions LLC also collects anonymous demographic information,
        which is not unique to you, such as your ZIP code, age, gender,
        preferences, interests and favorites.
      </p>
      <br />
      <p>
        There is also information about your computer hardware and software that
        is automatically collected by Surplus Solutions LLC. This information
        can include: your IP address, browser type, domain names, access times
        and referring Web site addresses. This information is used by Surplus
        Solutions LLC for the operation of the service, to maintain quality of
        the service, and to provide general statistics regarding use of the
        Surplus Solutions LLC Web site.
      </p>
      <br />
      <p>
        Please keep in mind that if you directly disclose personally
        identifiable information or personally sensitive data through Surplus
        Solutions LLC public message boards, this information may be collected
        and used by others. Note: Surplus Solutions LLC does not read any of
        your private online communications.
      </p>
      <br />
      <p>
        Surplus Solutions LLC encourages you to review the privacy statements of
        Web sites you choose to link to from Surplus Solutions LLC so that you
        can understand how those Web sites collect, use and share your
        information. Surplus Solutions LLC is not responsible for the privacy
        statements or other content on Web sites outside of the Surplus
        Solutions LLC and Surplus Solutions LLC family of Web sites.
      </p>
      <br />
      <h2>Use of Your Personal Information</h2>
      <p>
        Surplus Solutions LLC collects and uses your personal information to
        operate the Surplus Solutions LLC Web site and deliver the services you
        have requested. Surplus Solutions LLC also uses your personally
        identifiable information to inform you of other products or services
        available from Surplus Solutions LLC and its affiliates. Surplus
        Solutions LLC may also contact you via surveys to conduct research about
        your opinion of current services or of potential new services that may
        be offered.
      </p>
      <br />
      <p>
        Surplus Solutions LLC does not sell, rent or lease its customer lists to
        third parties. Surplus Solutions LLC may, from time to time, contact you
        on behalf of external business partners about a particular offering that
        may be of interest to you. In those cases, your unique personally
        identifiable information (e-mail, name, address, telephone number) is
        not transferred to the third party. In addition, Surplus Solutions LLC
        may share data with trusted partners to help us perform statistical
        analysis, send you email or postal mail, provide customer support, or
        arrange for deliveries. All such third parties are prohibited from using
        your personal information except to provide these services to Surplus
        Solutions LLC, and they are required to maintain the confidentiality of
        your information.
      </p>
      <br />
      <p>
        Surplus Solutions LLC does not use or disclose sensitive personal
        information, such as race, religion, or political affiliations, without
        your explicit consent.
      </p>
      <br />
      <p>
        Surplus Solutions LLC keeps track of the Web sites and pages our
        customers visit within Surplus Solutions LLC, in order to determine what
        Surplus Solutions LLC services are the most popular. This data is used
        to deliver customized content and advertising within Surplus Solutions
        LLC to customers whose behavior indicates that they are interested in a
        particular subject area.
      </p>
      <br />
      <p>
        Surplus Solutions LLC Web sites will disclose your personal information,
        without notice, only if required to do so by law or in the good faith
        belief that such action is necessary to: (a) conform to the edicts of
        the law or comply with legal process served on Surplus Solutions LLC or
        the site; (b) protect and defend the rights or property of Surplus
        Solutions LLC; and, (c) act under exigent circumstances to protect the
        personal safety of users of Surplus Solutions LLC, or the public.
      </p>
      <br />
      <h2>Use of Cookies</h2>
      <p>
        The Surplus Solutions LLC Web site use “cookies” to help you personalize
        your online experience. A cookie is a text file that is placed on your
        hard disk by a Web page server. Cookies cannot be used to run programs
        or deliver viruses to your computer. Cookies are uniquely assigned to
        you, and can only be read by a web server in the domain that issued the
        cookie to you.
      </p>
      <br />
      <p>
        One of the primary purposes of cookies is to provide a convenience
        feature to save you time. The purpose of a cookie is to tell the Web
        server that you have returned to a specific page. For example, if you
        personalize Surplus Solutions LLC pages, or register with Surplus
        Solutions LLC site or services, a cookie helps Surplus Solutions LLC to
        recall your specific information on subsequent visits. This simplifies
        the process of recording your personal information, such as billing
        addresses, shipping addresses, and so on. When you return to the same
        Surplus Solutions LLC Web site, the information you previously provided
        can be retrieved, so you can easily use the Surplus Solutions LLC
        features that you customized.
      </p>
      <br />
      <p>
        You have the ability to accept or decline cookies. Most Web browsers
        automatically accept cookies, but you can usually modify your browser
        setting to decline cookies if you prefer. If you choose to decline
        cookies, you may not be able to fully experience the interactive
        features of the Surplus Solutions LLC services or Web sites you visit.
      </p>
      <br />
      <h2>Security of Your Personal Information</h2>
      <p>
        Surplus Solutions LLC secures your personal information from
        unauthorized access, use or disclosure. Surplus Solutions LLC secures
        the personally identifiable information you provide on computer servers
        in a controlled, secure environment, protected from unauthorized access,
        use or disclosure. When personal information (such as a credit card
        number) is transmitted to other Web sites, it is protected through the
        use of encryption, such as the Secure Socket Layer (SSL) protocol.
      </p>
      <br />
      <h2>Changes to This Statement</h2>
      <p>
        Surplus Solutions LLC will occasionally update this Statement of Privacy
        to reflect company and customer feedback. Surplus Solutions LLC
        encourages you to periodically review this Statement to be informed of
        how Surplus Solutions LLC is protecting your information.
      </p>
      <br />
      <h2>Contact Information</h2>
      <p>
        Surplus Solutions LLC welcomes your comments regarding this Statement of
        Privacy. If you believe that Surplus Solutions LLC has not adhered to
        this Statement, please contact Surplus Solutions LLC at{" "}
        <a
          href="mailto:sales@ssllc.com"
          className="text-red-600 hover:underline"
        >
          sales@ssllc.com
        </a>
        . We will use commercially reasonable efforts to promptly determine and
        remedy the problem.
      </p>
      <br />
    </div>
  </Layout>
)

export default PrivacyPolicy
